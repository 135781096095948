import { FaInstagram } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import PlainButton from "../components/PlainButton";

export default function Contact() {
  return (
    <div className="font-Fredericka">
      <p className="text-4xl md:text-5xl text-center mt-14">
        Entre em contato por:
      </p>
      <div className="mx-16 my-5 space-y-4">
        <div className="border-4 border-igGrey px-4 pb-2">
          <p className="text-4xl font-medium underline truncate">Instagram</p>
          <div className="flex flex-wrap justify-center space-x-2 items-center font-bold my-2">
            <FaInstagram className="size-16 md:size-[24] flex-shrink-0" />
            <p className="text-3xl md:text-4xl truncate">@lefonsc</p>
          </div>
          <div className="text-2xl text-end">
            <PlainButton>
              <a href="https://www.instagram.com/lefonsc/" target="_blank">
                Clique aqui!
              </a>
            </PlainButton>
          </div>
        </div>


        <div className="border-4 border-igGrey px-4 pb-2">
          <p className="text-4xl font-medium underline truncate">E-mail</p>
          <div className="flex justify-center flex-wrap space-x-2 items-center font-bold my-2">
            <MdMailOutline className="size-16 md:size-[24] flex-shrink-0" />
            <p className="text-lg sm:text-xl md:text-4xl truncate">leticiafons.contato@gmail.com</p>
          </div>
          <div className="text-2xl text-end">
            <PlainButton>
              <a href="mailto:leticiafons.contato@gmail.com" target="_blank">
                Clique aqui!
              </a>
            </PlainButton>
          </div>
        </div>

      </div>
    </div>
  );
}
