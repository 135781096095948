import profile from "../assets/profile.png";
import { ReactTyped } from "react-typed";
import { FaInstagram, FaArrowRight } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import Card from "../components/Card";
import PlainButton from "../components/PlainButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { database } from "../firebase-config";
import { useAuth } from "../FirebaseHooks";

export default function Home(props) {
  const [firstProj, setFirstProj] = useState(null);
  const [secondProj, setSecondProj] = useState(null);
  const [thirdProj, setThirdProj] = useState(null);

  const {isLoggedIn} = useAuth();

  useEffect(() => {
    const getData = async () => {
      const highDoc = (
        await getDoc(doc(database, "projects", "highlighted"))
      ).data();

      for (const [key, value] of Object.entries(highDoc)) {
        const dbCollection = collection(database, "projects");
        const q = query(dbCollection, where("id", "==", value));

        const setDocuments = async () => {
          const queryResults = await getDocs(q);

          if (queryResults.docs[0] == null){ return;}

          if (key == "first") {
            setFirstProj(queryResults.docs[0].data());
          } else if (key == "second") {
            setSecondProj(queryResults.docs[0].data());
          } else if (key == "third") {
            setThirdProj(queryResults.docs[0].data());
          }
        };
        setDocuments();
      }
    };

    getData();
  }, []);

  return (
    <div className="size-full my-10 md:my-14 font-Fredericka space-y-2 xl:grid xl:grid-cols-3">
      <div className="text-center lg:col-span-1">
        <img
          className="w-[300px] h-[300px] rounded-3xl m-auto object-cover object-top shadow-lg shadow-igGrey"
          src={profile}
        />
        <h1 className="text-xl pt-5">Leticia Fonseca Coutinho</h1>
        <p className="text-igGrey italic text-sm -mt-1 pb-5">
          foto por: Miriane Figueira
        </p>
        <ReactTyped
          className="text-2xl font-semibold -z-10"
          strings={["Arte", "Animação", "Percussão", "Cultura Afro-Brasileira"]}
          loop
          typeSpeed={60}
          backSpeed={30}
        />

        <div className="flex justify-center mt-4 space-x-2">
          <a href="https://www.instagram.com/lefonsc/" target="_blank">
            <FaInstagram className="size-8 hover:cursor-pointer" />
          </a>
          <a href="mailto:leticiafons.contato@gmail.com" target="_blank">
            <MdMailOutline className="size-8 hover:cursor-pointer" />
          </a>
        </div>
      </div>

      <div className="md:p-2 lg:col-span-2">
        <p className="text-3xl font-extrabold  m-5">Projetos em destaque:</p>
        <div className="md:grid md:grid-cols-3 gap-4 mx-5">
          {firstProj != null && (
            <Card
              projectId={firstProj.id}
              editable={false}
              title={firstProj.title}
              description={firstProj.desc}
              img={firstProj.thumb}
            />
          )}

          {secondProj != null && (
            <Card
              projectId={secondProj.id}
              editable={false}
              title={secondProj.title}
              description={secondProj.desc}
              img={secondProj.thumb}
            />
          )}

          {thirdProj != null && (
            <Card
              projectId={thirdProj.id}
              editable={false}
              title={thirdProj.title}
              description={thirdProj.desc}
              img={thirdProj.thumb}
            />
          )}
        </div>

        <Link to={"/projects"} className="flex justify-center  md:float-end ">
          <PlainButton>
            <div className="flex items-center">
              <p className="text-xl p-1">Ver mais projetos</p>
              <FaArrowRight />
            </div>
          </PlainButton>
        </Link>
      </div>
    </div>
  );
}
