import PlainButton from "./PlainButton";
import Drawer from "./Drawer";
import { AiOutlineMenu } from "react-icons/ai";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useAuth } from "../FirebaseHooks";
import { auth } from "../firebase-config";

export default function Navbar(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isLoggedIn } = useAuth();

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    await auth.signOut();
  };

  return (
    <div className="fixed w-full top-0 inset-x-0 z-40  bg-igPink font-Fredericka flex justify-between text-2xl md:text-3xl">
      <button className="pt-2 px-2">
        <Link to={"/"}>
          <div className="font-bold -m-3">Leticia</div>
          <div className=" font-thin">FONSECA</div>
        </Link>
      </button>

      <ul className=" hidden my-auto md:flex p-2 space-x-3 justify-end text-xl md:text-2xl ">
        <li>
          {isLoggedIn && (
            <PlainButton className="m-auto" onClick={handleLogout}>
              Deslogar
            </PlainButton>
          )}
        </li>
        <li>          <Link to={"/"}>
            <PlainButton>Início</PlainButton>
          </Link>
        </li>
        <li>
          <Link to={"projects"}>
            <PlainButton>Projetos</PlainButton>
          </Link>
        </li>
        <li>
          <Link to={"contact"}>
            <PlainButton>Contato</PlainButton>
          </Link>
        </li>
        <li>
          <Link to={"about"}>
            <PlainButton>Sobre</PlainButton>
          </Link>
        </li>
      </ul>
      <button className="md:hidden my-auto  p-2 hover:scale-110 active:bg-black active:bg-opacity-20 ease-in-out duration-500 active:duration-100 rounded-full ">
        <AiOutlineMenu onClick={handleOpenDrawer} size={26} />
      </button>

      <Drawer isOpen={drawerOpen} onClose={handleCloseDrawer}>
        <div className="w-full flex justify-center">
          <ul className="w-1/2 space-y-8 text-2xl z-40">
            <li>
              {isLoggedIn && (
                <PlainButton className="m-auto" onClick={handleLogout}>
                  Deslogar
                </PlainButton>
              )}
            </li>
            <li>
              <Link to={"/"}>
                <PlainButton onClick={handleCloseDrawer}>Início</PlainButton>
              </Link>
            </li>
            <li>
              <Link to={"projects"}>
                <PlainButton onClick={handleCloseDrawer}>Projetos</PlainButton>
              </Link>
            </li>
            <li>
              <Link to={"contact"} onClick={handleCloseDrawer}>
                <PlainButton>Contato</PlainButton>
              </Link>
            </li>
            <li>
              <Link to={"about"} onClick={handleCloseDrawer}>
                <PlainButton onClick={handleCloseDrawer}>Sobre</PlainButton>
              </Link>
            </li>
          </ul>
        </div>
      </Drawer>
    </div>
  );
}
