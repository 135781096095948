import { Link } from "react-router-dom";
import profile from "../assets/profile.png";
import { FaArrowRight } from "react-icons/fa";
import PlainButton from "../components/PlainButton";

export default function About() {
  return (
    <div className="m-4 font-Fredericka font-bold">
      <div className="text-center lg:col-span-1">
        <img
          className="w-[300px] h-[300px] rounded-3xl m-auto object-cover object-top shadow-lg shadow-igGrey"
          src={profile}
        />
        <h1 className="text-xl pt-5">Leticia Fonseca Coutinho</h1>
        <p className="text-igGrey italic text-sm -mt-1 pb-5">
          foto por: Miriane Figueira
        </p>

        <h1 className="text-xl text-left ml-5">Sobre:</h1>
        <p className="text-balance text-lg md:text-3xl m-2">
          Leticia Fonseca Coutinho (Curitiba, 2002), é, atualmente, formanda de
          Bacharelado em Artes Visuais pela Universidade Federal do Paraná
          (2020-24). Sendo artista visual e percussionista, eu busco, na maioria
          de minhas produções, formas de explorar e expandir elementos da
          percussão e cultura afro-brasileira através da arte. Os meus
          interesses no momento, se voltam para a fotografia, a animação e a
          gravura, entrelaçando as várias linguagens.
          <p>Obs: O nome "Leticia", de minha pessoa, não tem acento mesmo :)</p>
        </p>

        <Link to={"/projects"} className="w-full flex justify-center ">
          <PlainButton>
            <div className="flex justify-center items-center">
              <p className="text-3xl p-1">Ver projetos</p>
              <FaArrowRight className="mt-1" />
            </div>
          </PlainButton>
        </Link>

        <Link to={"/contact"} className="w-full flex justify-center ">
          <PlainButton>
            <div className="flex justify-center items-center">
              <p className="text-3xl p-1">Entrar em contato</p>
              <FaArrowRight className="mt-1" />
            </div>
          </PlainButton>
        </Link>
      </div>
    </div>
  );
}
